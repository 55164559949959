import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import portal from "@vacancorp/portal";

// types guard
export function isPublicPlaceWithVacancyId(data: unknown): data is portal.PublicPlaceWithVacancyId {
  return (
    typeof (data as portal.PublicPlaceWithVacancyId).placeIdHash === "string" &&
    typeof (data as portal.PublicPlaceWithVacancyId).statusOrder === "number"
  );
}

export function isResponseGetPlaceDetail(data: unknown): data is PortalAdapterApi.ResponseGetPlaceDetail {
  return (
    typeof (data as PortalAdapterApi.ResponseGetPlaceDetail).placeIdHash === "string" &&
    typeof (data as PortalAdapterApi.ResponseGetPlaceDetail).isInEvacuationDrill === "boolean"
  );
}

export type AdsPopup = {
  content: string;
  areaName: string;
};
