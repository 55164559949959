import { AxiosResponse, AxiosError } from "axios";
import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import { ResponseGetEvacuationDrillMode } from "@/@types/evacuation-drill";
import { isResponseGetEvacuationDrillMode } from "@/usecase/evacuation-drill";
import $http from "../api-datastores/http";
import store from "@/store";
import i18n from "@/i18n";
import { genreCategoryListToString } from "@/mixins/common-functions";
const ErrorApi = { message: "API error", name: "" };
const ErrorConnection = { message: "Connection error", name: "" };
const ErrorParameter = { message: "Parameter error", name: "" };

import { Auth } from "@/repositories/auth";
const auth = new Auth();

/** @remarks place category (genre) */
export async function fetchPlaceCategoryList(
  countryName: string,
): Promise<PortalAdapterApi.ResponseGetPlaceCategoryList> {
  const language = i18n.locale;

  return await $http
    .get(`/v1/place-categories`, { params: { language, countryName } })
    .then((response: AxiosResponse) =>
      response.status === 200
        ? (response.data as PortalAdapterApi.ResponseGetPlaceCategoryList)
        : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks access areaName directly */
export async function fetchLocationByAreaName(areaName: string): Promise<PortalAdapterApi.TopArea> {
  const language = i18n.locale;
  return await $http
    .get(`/v1/places/area/${areaName}`, {
      params: { language, unixtime: Date.now() / 1000 },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.TopArea) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks Same building list */
export async function fetchSameBuildingList(
  buildingIdHash: string,
): Promise<PortalAdapterApi.ResponseGetPublicPlace[]> {
  const language = i18n.locale;
  return await $http
    .get(`/v1/buildings/${buildingIdHash}/places`, {
      params: { language, unixtime: Date.now() / 1000 },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.ResponseGetPublicPlace[]) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks Top Area Places */
export async function fetchTopAreaCountryList(): Promise<PortalAdapterApi.ResponseGetTopAreaCountryList> {
  const language = i18n.locale;
  return await $http
    .get("/v1/places/top-areas/countries", { params: { language } })
    .then((response: AxiosResponse) =>
      response.status === 200
        ? (response.data as PortalAdapterApi.ResponseGetTopAreaCountryList)
        : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/**
 * @remarks 投票所のエリアあるかどうか
 * */
export async function fetchHasPollPlaces(mapsMapType: string = "polling-place"): Promise<boolean> {
  return await $http
    .get("/v1/places/top-areas/existence", {
      params: {
        mapsMapType,
      },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? Promise.resolve(Boolean(response.data)) : Promise.resolve(false),
    )
    .catch(() => Promise.resolve(false));
}
/**
 * @remarks mapsMapTypeに依頼するエリアfetch
 * */
export async function fetchTopAreaPlaceList(
  countryName: string = "jp",
  mapsMapType: string = "normal",
): Promise<PortalAdapterApi.ResponseGetTopAreaList> {
  const language = i18n.locale;
  return await $http
    .get("/v1/places/top-areas", {
      params: {
        language,
        countryName,
        mapsMapType,
      },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.ResponseGetTopAreaList) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks ショップ情報 */
export type FilterParams = {
  // filters
  vacant?: string;
  favorite?: string;
  genre?: string;
  genreCategoryList: string[];
};

export async function fetchPlaceListByLocation(
  lat: number,
  lng: number,
  radius: number, // meter
  countryName: string,
  params: FilterParams,
  areaName?: string,
  mapType?: string,
): Promise<PortalAdapterApi.ResponseGetPublicPlace[]> {
  // filters
  const vacant = params?.vacant,
    favorite = params?.favorite,
    genre = params?.genre === "all" ? undefined : params?.genre;
  // convert genreCategory
  let genreCategoryList = genreCategoryListToString(params.genreCategoryList);
  if (genreCategoryList === "") genreCategoryList = undefined;

  const language = i18n.locale;
  if (isNaN(lat) || isNaN(lng) || isNaN(radius)) {
    return Promise.reject(ErrorParameter);
  }

  radius = radius / 1000; // change to send km

  return await $http
    .get("/v1/places", {
      params: {
        language,
        baseLocation: `${lat},${lng}`,
        radius,
        unixtime: Date.now() / 1000,
        countryName,
        vacant,
        favorite,
        genre,
        genreCategoryList,
        areaName,
        mapsMapType: mapType,
      },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.ResponseGetPublicPlace[]) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => {
      return error.response?.status === 404
        ? Promise.resolve([])
        : Promise.reject(error.response ? error.response.data.errors : ErrorConnection);
    });
}

export async function fetchGenreCategoryList(genreName: string): Promise<PortalAdapterApi.GenreCategoryType> {
  const language = i18n.locale;
  return await $http
    .get(`/v1/genre/${genreName}/categoryList`, { params: { language } })
    .then((response: AxiosResponse) => (response.status === 200 ? response.data : Promise.reject(ErrorApi)))
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks 空き状態 */
export async function fetchFacilityVacancyListByPlaceIdHashList(
  placeIdHashList: string[],
): Promise<PortalAdapterApi.ResponseGetMergedDisplayVacancy[]> {
  if (placeIdHashList.length <= 0) {
    return Promise.reject(ErrorParameter);
  }
  const language: string = store.state.data.language;
  const placeIdHashCsv: string = placeIdHashList.join(",");
  return await $http
    .get(`/v1/places/${placeIdHashCsv}/vacancy`, {
      params: { language, target: "portal", unixtime: Date.now() / 1000 },
    })
    .then((response: AxiosResponse) =>
      response.status === 200
        ? (response.data as PortalAdapterApi.ResponseGetMergedDisplayVacancy[])
        : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks 詳細ページ */
export async function fetchPlaceDetailByPlaceIdHash(
  placeIdHash: string,
): Promise<PortalAdapterApi.ResponseGetPlaceDetail> {
  if (placeIdHash.length === 0) {
    return Promise.reject(ErrorParameter);
  }
  const language = i18n.locale;
  return await $http
    .get(`/v1/place/${placeIdHash}/detail`, {
      params: { language, unixtime: Date.now() / 1000 },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.ResponseGetPlaceDetail) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks ショップの画像 */
export async function fetchPlaceImagesByPlaceIdHash(
  placeIdHash: string,
  page: number = 1,
): Promise<PortalAdapterApi.ResponseGetShopImage[]> {
  if (placeIdHash.length === 0) {
    return Promise.reject(ErrorParameter);
  }
  return await $http
    .get(`/v1/place/${placeIdHash}/images/collections`, {
      params: { page, length: store.state.ui.IMGPAGENUMBER },
    })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.ResponseGetShopImage[]) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/** @remarks Couponページ */
export async function fetchCouponListByPlaceIdHash(
  placeIdHash: string,
): Promise<PortalAdapterApi.ResponseGetPublicCouponForPortal[]> {
  if (placeIdHash.length === 0) {
    return Promise.reject(ErrorParameter);
  }
  const language = i18n.locale;
  return await $http
    .get(`/v1/place/${placeIdHash}/coupons`, {
      params: { language, unixtime: Date.now() / 1000 },
    })
    .then((response: AxiosResponse) =>
      response.status === 200
        ? (response.data as PortalAdapterApi.ResponseGetPublicCouponForPortal[])
        : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

export async function postCampaignCouponCheckIn(placeId: string, couponId: string): Promise<boolean> {
  return await $http
    .post(`/v1/place/${placeId}/campaign-coupon`, {
      couponIdHash: couponId,
      requestUnixtime: Date.now() / 1000,
    } as PortalAdapterApi.RequestPostCouponHistory)
    .then((response: AxiosResponse) => {
      return response.status === 200 || response.status === 204;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}

/** @remarks お知らせ */
export async function fetchAnnouncementNumber(): Promise<number> {
  return await $http
    .get("/v1/notices/total-number")
    .then((response: AxiosResponse) => (response.status === 200 ? (response.data as number) : Promise.reject(ErrorApi)))
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

export async function fetchAnnouncementList(): Promise<PortalAdapterApi.ResponseGetNotice[]> {
  const language = i18n.locale;
  return await $http
    .get(`/v1/notices`, { params: { language } })
    .then((response: AxiosResponse) =>
      response.status === 200 ? (response.data as PortalAdapterApi.ResponseGetNotice[]) : Promise.reject(ErrorApi),
    )
    .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ErrorConnection));
}

/**
 * @remarks Login functions
 * */

export async function login({
  email,
  password,
}: PortalAdapterApi.RequestPostLogin): Promise<PortalAdapterApi.ResponsePostLogin> {
  return await $http
    .post(`/v1/user/signin`, { email, password })
    .then((response: AxiosResponse) => {
      if (!auth.isResponsePostLogin(response.data)) {
        throw new Error();
      }
      return response.data as PortalAdapterApi.ResponsePostLogin;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}

export async function logout(): Promise<boolean> {
  return await $http
    .post(`/v1/user/signout`)
    .then((response: AxiosResponse) => {
      return response.status === 200 || response.status === 401;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}

export async function renewSession(): Promise<PortalAdapterApi.ResponsePostLogin> {
  return await $http
    .post(`/v1/user/session`, {
      refreshToken: localStorage.getItem("session/tokens/refreshToken"),
    })
    .then((response: AxiosResponse) => {
      if (!auth.isResponsePostLogin(response.data)) {
        throw new Error();
      }
      return response.data as PortalAdapterApi.ResponsePostLogin;
    })
    .catch(() => {
      return Promise.reject();
    });
}

export async function signup({ email }: { email: string }): Promise<boolean> {
  const language: string = store.state.data.language;
  return await $http
    .post(`/v1/user/signup`, { email, language })
    .then((response: AxiosResponse) => {
      if (response.status >= 400) throw new Error();
      return response.status === 200 || response.status === 204;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}
export async function activate({ email, confirmationCode }: PortalAdapterApi.RequestPostActivate): Promise<number> {
  return await $http
    .post(`/v1/user/activate`, { email, confirmationCode })
    .then((response: AxiosResponse) => {
      return response.status as number;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}
export async function forgotPassword({ email }: PortalAdapterApi.RequestPostForgotPassword): Promise<boolean> {
  return await $http
    .post(`/v1/user/forgot-password`, { email })
    .then((response: AxiosResponse) => {
      if (response.status >= 400) throw new Error();
      return response.status === 200 || response.status === 204;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}
export async function changePassword({
  oldPassword,
  newPassword,
}: PortalAdapterApi.RequestPostChangePassword): Promise<number> {
  return await $http
    .post(`/v1/user/change-password`, { oldPassword, newPassword })
    .then((response: AxiosResponse) => {
      return response.status as number;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}
export async function resetPassword({
  email,
  newPassword,
  confirmationCode,
}: PortalAdapterApi.RequestPostResetPassword): Promise<boolean> {
  return await $http
    .post(`/v1/user/reset-password`, { email, newPassword, confirmationCode })
    .then((response: AxiosResponse) => {
      if (response.status >= 400) throw new Error();
      return response.status === 200 || response.status === 204;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}

/** @remarks Favorite */
export async function getFavoriteList(): Promise<PortalAdapterApi.ResponseGetPublicPlace[]> {
  const language = i18n.locale;
  return await $http
    .get(`/v1/favorites`, { params: { language, unixtime: Date.now() / 1000 } })
    .then((response: AxiosResponse) => {
      return response.data as PortalAdapterApi.ResponseGetPublicPlace[];
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}
export async function createFavoritePlace(placeIdHash: string): Promise<boolean> {
  return await $http
    .post(`/v1/favorite`, { placeIdHash })
    .then((response: AxiosResponse) => {
      return response.status === 201;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}
export async function deleteFavoritePlace(placeIdHash: string): Promise<boolean> {
  return await $http
    .delete(`/v1/favorite/${placeIdHash}`)
    .then((response: AxiosResponse) => {
      return response.status === 204;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}

/** @remarks realtime update evacuation drills */
export async function getEvacuationDrill(
  placeIdHashList: string[],
): Promise<ResponseGetEvacuationDrillMode[] | undefined> {
  return await $http
    .get(`/v1/places/${placeIdHashList}/evacuation-drill`, {
      params: { unixtime: Date.now() / 1000 },
    })
    .then((response: AxiosResponse) => {
      try {
        if (typeof response.data === "object" && response.data.length > 0) {
          const validate = response.data.every((d: ResponseGetEvacuationDrillMode) =>
            isResponseGetEvacuationDrillMode(d),
          );
          return validate ? response.data : undefined;
        } else {
          return undefined;
        }
      } catch {
        return Promise.reject(undefined);
      }
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error.response ? error.response.data.errors : { message: "connection error", name: "" });
    });
}

// TODO: Genreと関わらずnotificationを統合
export default {
  // filter
  fetchGenreCategoryList,
  // favorite
  getFavoriteList,
  createFavoritePlace,
  deleteFavoritePlace,
  // places
  fetchPlaceCategoryList,
  fetchLocationByAreaName,
  fetchSameBuildingList,
  fetchTopAreaCountryList,
  fetchTopAreaPlaceList,
  fetchCouponListByPlaceIdHash,
  fetchAnnouncementNumber,
  fetchAnnouncementList,
  fetchPlaceListByLocation,
  fetchFacilityVacancyListByPlaceIdHashList,
  fetchPlaceDetailByPlaceIdHash,
  fetchPlaceImagesByPlaceIdHash,
  //login
  login,
  logout,
  renewSession,
  signup,
  changePassword,
  forgotPassword,
  resetPassword,
  activate,
};
