import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import portal from "@vacancorp/portal";
import { isArray } from "@/util/lodash";
import { ResponseGetEvacuationDrillMode, FuncResponseGetEvacuationDrillMode } from "@/@types/evacuation-drill";
import {
  isPublicPlaceWithVacancyId,
  isResponseGetPlaceDetail,
} from "@/@types/places";
import { getEvacuationDrill } from "@/api/adapter.api";

/** @remarks realtime evacuation drill status */
export async function getEvacuationDrillStatus(
  content: unknown
): Promise<ResponseGetEvacuationDrillMode[] | undefined> {
  let placeIdHashList: string[] | undefined;
  if (isArray(content)) {
    // map places
    // same building
    // favorite list
    if (
      isPublicPlaceWithVacancyId(
        (content as portal.PublicPlaceWithVacancyId[])[0]
      )
    ) {
      placeIdHashList = (content as portal.PublicPlaceWithVacancyId[]).map(
        (place) => place.placeIdHash
      );
    }
  } else {
    // detail page
    if (isResponseGetPlaceDetail(content)) {
      placeIdHashList = [
        (content as PortalAdapterApi.ResponseGetPlaceDetail).placeIdHash,
      ];
    }
  }

  if (placeIdHashList === undefined) return undefined;
  return await getEvacuationDrill(placeIdHashList).catch(() => undefined);
}

export function hasDiffEvacuationDrillPlaces<T>(
  list: ResponseGetEvacuationDrillMode[] | undefined,
  targetListOrObject?: Array<T>
): boolean {
  if (list === undefined || targetListOrObject === undefined) return false;
  let drillStatusDiffList: ResponseGetEvacuationDrillMode[] = [];
  list.forEach((drill) => {
    const place: T | undefined = targetListOrObject.find(
      (t: any) => t?.placeIdHash === drill.placeIdHash
    );
    if (place === undefined) return;
    if ((place as any)?.isInEvacuationDrill !== drill.isInEvacuationDrill) {
      drillStatusDiffList.push({
        placeIdHash: drill.placeIdHash,
        isInEvacuationDrill: drill.isInEvacuationDrill,
      });
    }
  });
  return drillStatusDiffList.length > 0;
}

export function prepareEvacuationDrillList<T>(
  list: ResponseGetEvacuationDrillMode[] | undefined,
  targetListOrObject?: Array<T>
): Array<T> | undefined {
  if (list === undefined || targetListOrObject === undefined) return;
  if (isArray(targetListOrObject)) {
    if (isPublicPlaceWithVacancyId((targetListOrObject as T[])[0])) {
      const newSortedPlacesList: T[] = (targetListOrObject as T[]).map(
        (place: any) => {
          const target: ResponseGetEvacuationDrillMode | undefined = list.find(
            (l) => l.placeIdHash === place.placeIdHash
          );
          return {
            ...place,
            isInEvacuationDrill:
              target === undefined ? false : target.isInEvacuationDrill,
          };
        }
      );
      return newSortedPlacesList;
    } else return undefined;
  }
}

export function prepareEvacuationDrillObject<T>(
  list: ResponseGetEvacuationDrillMode[] | undefined,
  targetListOrObject?: T
): T | undefined {
  if (list === undefined || targetListOrObject === undefined) return;
  if (isResponseGetPlaceDetail(targetListOrObject)) {
    const target: ResponseGetEvacuationDrillMode | undefined = list.find(
      (l) =>
        l.placeIdHash ===
        (targetListOrObject as PortalAdapterApi.ResponseGetPlaceDetail)
          .placeIdHash
    );
    return {
      ...(targetListOrObject as T),
      isInEvacuationDrill:
        target === undefined ? false : target.isInEvacuationDrill,
    };
  } else return undefined;
}

export const isResponseGetEvacuationDrillMode: FuncResponseGetEvacuationDrillMode = function (
  mode: unknown,
): mode is ResponseGetEvacuationDrillMode {
  return (
    typeof (mode as ResponseGetEvacuationDrillMode).placeIdHash === "string" &&
    typeof (mode as ResponseGetEvacuationDrillMode).isInEvacuationDrill === "boolean"
  );
};