import { AccessToken } from "@/@types/auth";
import { Auth } from "@/repositories/auth";
import dayjs from "dayjs";
import { setLocalStorage } from "@/util/utils";

export function hasAccessToken(): boolean {
  const accessToken: string = localStorage.getItem("session/tokens/accessToken") || "";
  if (accessToken === null || accessToken.length === 0) {
    return false;
  } else {
    return true;
  }
}

export function isAccessTokenValid(): boolean {
  const auth = new Auth();
  const accessToken: string = localStorage.getItem("session/tokens/accessToken") || "";
  if (!accessToken || !accessToken.includes("Bearer ")) {
    return false;
  }
  auth.accessToken = auth.parseJwt(accessToken) as unknown as AccessToken;
  if (auth.accessToken.exp === undefined) return false;
  return auth.accessToken.exp > dayjs().unix();
}

export function setTokens(accessToken?: string, refreshToken?: string) {
  setLocalStorage("session/tokens/accessToken", !accessToken ? undefined : accessToken);
  setLocalStorage("session/tokens/refreshToken", !refreshToken ? undefined : refreshToken);
}

export const authorizationItem = (): string => {
  return `Bearer ${localStorage.getItem("session/tokens/accessToken") || ""}`;
};

export const haveSession = (): boolean => {
  const token: string | null = localStorage.getItem("session/tokens/accessToken");
  return token !== null && token.length > 0;
};
