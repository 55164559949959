import Vue from "vue";
import Router, { Route } from "vue-router";
import { renewSession } from "../api/adapter.api";
import i18n from "@/i18n";
import store from "../store";
import { toLongLang, toShortLang } from "@/mixins/common-functions";
import { isMobile } from "@/util/mobile";
import { router as mobileRouter } from "@/router/router-mobile";
import { router as pcRouter } from "@/router/router-pc";

Vue.use(Router);

const router = isMobile() ? mobileRouter : pcRouter;

/**
 * @remarks renew login session
 */
import { isAccessTokenValid } from "@/usecase/token-operators";
import { PortalAdapterApi } from "@vacancorp/portal.adapter.api";
import { parameterizedURLQuery } from "@/mixins/common-functions";
import { setTokens } from "@/usecase/token-operators";

function replaceTitleTab(to: Route) {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
}

function setHtmlLang(locale: string) {
  const documentRoot = document.getElementsByTagName("html")[0];
  if (documentRoot) {
    documentRoot.lang = locale;
  }
}

router.beforeEach((to: Route, _: Route, next: (route?: string) => void) => {
  // route with language prefix
  const { lang } = to.params;

  if (lang === "en" || lang === "tw" || lang === "cn" || lang === "ko") {
    store.commit("data/setLanguage", toLongLang(lang));
    i18n.locale = toLongLang(lang);
    setHtmlLang(i18n.locale);
  } else if (lang === undefined) {
    if (i18n.locale === undefined) {
      store.commit("data/setLanguage", "ja-JP");
      i18n.locale = "ja-JP";
    }
    // redirect exsited route.push()
    const savedLang = i18n.locale;
    const short = toShortLang(savedLang);
    if (short !== undefined) {
      console.log(`url query: ${JSON.stringify(to.query)}`);
      if (to.query !== undefined) {
        next(`/${short}${to.path}?${parameterizedURLQuery(to.query as { [Key: string]: string })}`);
      } else {
        next(`/${short}${to.path}`);
      }
    }
    // next(`${to.path}`);
  } else {
    store.commit("data/setLanguage", "ja-JP");
    i18n.locale = "ja-JP";
    const pathes: RegExpMatchArray | null = to.path.match(`^/${lang}/(.*)`);
    if (pathes === null) return next();
    const jpPath = `/${pathes[1]}`;
    next(jpPath);
  }

  // replace title from router setting
  replaceTitleTab(to);

  // check routers meta
  const needAuthentication: boolean = to.matched.some(
    (record) => record.meta.requireLogin !== undefined && record.meta.requireLogin.length > 0,
  );

  if (!needAuthentication) {
    return next();
  }

  if (!isAccessTokenValid()) {
    renewSession()
      .then((result: PortalAdapterApi.ResponsePostLogin | undefined) => {
        if (result === undefined || result.accessToken === "") {
          throw new Error("Failed to renew token");
        }
        setTokens(result.accessToken, result.refreshToken);
      })
      .catch(() => {
        setTokens(undefined, undefined);
        return next("/login");
      });
  } else {
    return next();
  }

  next();
});

export default router;
